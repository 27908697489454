import L from 'leaflet'
import { isOffline } from '@/components/utils/mobile/common'
import { QGIS_URL } from '@/config/baseUrl_local'
import { maxZoom, urlTileESRI } from '@/config/common'

export default {
  computed: {
    osm () {
      return new L.TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: maxZoom, maxNativeZoom: 19 })
    }
  },
  methods: {
    clearLayersControls () {
      if (this.controlLayers.layers) {
        this.map.removeControl(this.controlLayers.layers)
        this.controlLayers.layers = null
        /* } else {
           this.initTileLayers()*/
      }
    },
    getOverlayBySelectedType () {
      return this.modelStatic.overlayControls(this.info)
    },
    initTileLayers () {
      if (this.controlLayers.tile) {
        this.controlLayers.tile.forEach(tile => {
          tile.layer.remove()
        })
      }

      /*let osm = new L.TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors' })*/
      this.osm.addTo(this.map)

      let google = new L.TileLayer.WMS('https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
        layers: '',
        maxZoom: maxZoom,
        maxNativeZoom: 21
      })

      const ESRI = new L.TileLayer.WMS(urlTileESRI, {
        layers: '',
        maxZoom: maxZoom,
        maxNativeZoom: 19
      })

      /*let satellite = new L.TileLayer('https://api.maptiler.com/tiles/satellite-v2/{z}/{x}/{y}.jpg?key=FX6DLRQreQl4wMb9YzSW')
      satellite.addTo(this.map)

      let schemeWhite = new L.TileLayer('https://api.maptiler.com/maps/winter/256/{z}/{x}/{y}.png?key=FX6DLRQreQl4wMb9YzSW')
      schemeWhite.addTo(this.map)*/

      this.controlLayers.tile = [
        /*{
          label: 'Спутник',
          layer: satellite
        },
        {
          label: 'Схема Белая',
          layer: schemeWhite
        },*/
        {
          label: 'Схема OSM',
          layer: this.osm
        },
        {
          label: 'Google спутник',
          layer: google
        },
        {
          label: 'ESRI Satellite',
          layer: ESRI
        }
      ]
    },
    async passportTileLayers () {
      if (!(await isOffline())) {
        let tiles = await this.$store.dispatch('api/get', {
          url: 'map/layers?project_id=' + this.info.detail.properties.project_id + '&is_added=1'
        })
        if (tiles.data.models) {
          tiles.data.models.forEach(tile => {
            let customTiles = new L.TileLayer.WMS(`${QGIS_URL}/ogc/${this.info.detail.properties.id}`, {
              layers: 'OpenStreetMap,' + tile.label,
              maxZoom: maxZoom
            })
            this.controlLayers.tile.push({ label: tile.label, layer: customTiles })
          })
        }
      }
    }
  }
}
