// здесь логика без привязки к store
// используем в BaseGeometry

import L from 'leaflet'
import { styles } from '@/components/views/account/map/detail/common/mapStyles'

export default {
  methods: {
    async initModel () {
      if (this.detail.geometry.type === 'Point') {
        await this.initPoint()
      } else {
        this.initOthersElements()
      }

      this.fillLayer()

      this.setCurrentPosition()
      this.map._container.focus()
    },
    async initPoint () {
      if (!this.geoJson.server || !this.geoJson.local) {
        let canvas = null
        this.map.createPane('passportObjects')
        this.geoJson.server = {}
        this.geoJson.local = {}

        canvas = L.canvas({ pane: 'passportObjects' })
        canvas.options.pmIgnore = true
        this.geoJson.server.Point = {}
        this.geoJson.local.Point = {}
        const style = this.detail.properties.style
        const layer = L.geoJSON(null, {
          renderer: canvas,
          pointToLayer: function (geoJson, latlng) {
            return L.circleMarker(latlng, {
              renderer: canvas,
              radius: 5,
              fillColor: style.color,
              weight: style.weight || 2,
              fillOpacity: 1,
              pmIgnore: true
            })
          },
          style: this.detail.properties.style
        })

        this.geoJson.server.Point[this.detail.properties.id] = layer.addTo(this.map)
        this.geoJson.server.Point[this.detail.properties.id].options.pmIgnore = true
      }
      return true
    },
    initOthersElements () {
      if (!this.geoJson.server || !this.geoJson.local) {
        const layer = L.geoJSON(null, {
          style: this.getStyle()
        })
        this.geoJson.server = layer.addTo(this.map)
        this.geoJson.server.options.pmIgnore = true
        this.geoJson.local = layer.addTo(this.map)
        this.geoJson.local.options.pmIgnore = true
      }
      return true
    },
    fillLayer () {
      if (this.detail.geometry.type === 'Point') {
        const type = this.detail.geometry.type
        this.geoJson.server[type][this.detail.properties.id].addData({
          type: 'FeatureCollection',
          features: [this.detail]
        })
        return true
      } else {
        this.geoJson.server.addData({ type: 'FeatureCollection', features: [this.detail] })
      }
      return true
    },
    getStyle () {
      return this.styles || styles.defaultStyle.passport
    },
    setCurrentPosition () {
      const zooms = {
        region: 11,
        passport: 14,
        object: 18
      }
      const geoJson = L.geoJSON({ type: 'Feature', ...this.detail.geometry }) //создание geoJson
      this.map.flyTo(geoJson.getBounds().getCenter(), zooms[this.type], {animate: this.withAnimate})
      this.type !== 'object' && this.map.fitBounds(geoJson.getBounds())
      return true
    }
  }
}
