<template>
  <v-card
    class="pa-0 mt-0"
    flat
    :height="height"
    style="overflow: hidden"
  >
    <div class="pt-3 pb-2">
      <BaseAlert
        v-if="!value.properties.id"
        class="btn"
        type="info"
        text="Перед добавлением геометрии к сущности сохраните ее"
      />
      <BaseBtn
        v-else
        title="Загрузить геометрию"
        @click="handleImport"
        class="btn"
        v-show="!disabled"
      />
      <BaseDialog
        v-model="importDialog"
        is-cross-close
        label="Менеджер импорта"
        without-sides-padding
        little-label
        without-bot-padding
      >
        <template #subtitle>
          <div class="text-h4 font-weight-bold">Геометрии</div>
        </template>
        <template #content>
          <BaseImportPanel
            :file-type="fileType"
            :next-step-start-after="nextStepStartAfter"
            :previous-step-end-after="previousStepEndAfter"
            :params-to-upload="paramsToUpload"
            @parse:completed="completedLoad"
          />
        </template>
      </BaseDialog>
      <!--      <BaseImportPanel
              :extra-data="{
                commandType: 'parsing',
                params: {
                  entityId: detail.properties.id,
                  objectType: 'geometry'
                }
              }"
              :entity-id="detail.properties.id"
              :object-type="'geometry'"
              file-type="geojson"
              v-model="importDialog"
              @parse:completed="completedLoad"
            />-->
    </div>
    <l-map
      ref="baseMap"
      :center.sync="localCenter"
      :zoom.sync="localZoom"
      :options="{zoomControl: false, drawControl: false}"
      style="z-index: 0"
      :max-zoom="24"
      class="mt-2 map"
    >
      <div
        v-if="!detail.geometry"
        style="width: 100%; height: 100%; background-color: rgba(11,11,11,0.47); z-index: 1000; position: absolute;"
      >
        <v-card-title class="white--text no-geometry">Нет геометрии</v-card-title>
      </div>
      <!--      <l-control-scale-->
      <!--        position="bottomright"-->
      <!--        :imperial="false"-->
      <!--        :metric="true"-->
      <!--      ></l-control-scale>-->
      <l-control-zoom
        position="bottomright"
      ></l-control-zoom>
    </l-map>
  </v-card>
</template>

<script>
import mapStore from '@/components/mixins/map/store'
import controlLayer from '@/components/mixins/map/helpers/mapInit'
import modelHandler from '@/components/mixins/map/baseMap/modelHandler'
import { cleanClone } from '@/components/utils/common'
import BaseBtn from '@/components/base/UI/BaseBtn'
import PassportImportPanel from '@/components/views/account/passport/common/PassportImportPanel'
/*import BaseImportPanel from '@/components/base/BaseImportPanel'*/
import importMixin from '@/components/mixins/import'
import BaseAlert from '@/components/base/UI/BaseAlert'
import BaseImportPanel from '@/components/base/baseImportPanel/BaseImportPanel'
import BaseDialog from '@/components/base/BaseDialog'

const PARSING_TYPE = 'geometry'
const COMMAND_TYPE = 'parsing'

export default {
  name: 'BaseGeometry',
  components: { BaseDialog, BaseImportPanel, BaseBtn, PassportImportPanel, BaseAlert },
  mixins: [mapStore, controlLayer, modelHandler, importMixin],
  props: {
    value: Object,
    geometryType: String, // passport, region, object
    height: {
      default: '89vh',
      type: String
    },
    styles: Object,
    disabled: { Boolean, default: false }, //пропс передается из PassportObjectDetail, идет проверка роли "auditor",
    withAnimate: { type: Boolean, default: true }
  },
  data () {
    return {
      detail: cleanClone(this.value),
      controlLayers: {
        measure: null,
        layers: null,
        tile: null,
        overlay: {}
      },
      geoJson: {
        local: null,
        server: null
      },
      localCenter: cleanClone(this.center),
      localZoom: cleanClone(this.zoom),
      fileType: 'geojson',
      nextStepStartAfter: 1,
      previousStepEndAfter: 3,
      paramsToUpload: {
        commandType: COMMAND_TYPE,
        params: {
          geometryType: this.geometryType,
          parsingType: PARSING_TYPE,
          entityId: this.value.properties.id
        }
      }
    }
  },
  watch: {
    value: {
      handler () {
        this.detail = cleanClone(this.value)
      },
      deep: true
    }
  },
  computed: {
    isLoading () {
      return this.info.isLoading
    },
    map () {
      return this.$refs.baseMap.mapObject
    },
    isCollapse () {
      return this.$store.getters['map/isCollapseMapInfo']
    }
  },
  methods: {
    async completedLoad () {
      const response = await this.$store.dispatch('server/get', {
        url: this.geometryType + '/detail/' + this.detail.properties.id
      })
      this.$emit('loaded', response)
    },
    handleStartLoading () {
      this.info.isLoading = true
    },
    handleEndLoading () {
      this.info.isLoading = false
    },
    changeCollapse () {
      this.$store.commit('map/changeCollapse')
    }
  },
  async mounted () {
    this.$set(this.detail.properties, 'style', this.styles)

    this.initTileLayers()
    return this.$nextTick(async () => {
      this.detail.geometry && await this.initModel()
      document.addEventListener('layer:startLoading', this.handleStartLoading)
      document.addEventListener('layer:endLoading', this.handleEndLoading)
      return true
    })

  },
  async beforeDestroy () {
    document.removeEventListener('layer:startLoading', this.handleStartLoading)
    document.removeEventListener('layer:endLoading', this.handleEndLoading)
  }
}
</script>

<style scoped>
  .no-geometry {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn {
    position: absolute;
    z-index: 1;
    top: 33px;
  }
  .map {
    position: relative;
  }
</style>
