import L from 'leaflet'
import layersControls from '@/components/mixins/map/helpers/layersControls'
import { measureConfig } from '@/components/mixins/map/helpers/measuerControls'
import Vue from 'vue'
import BasePortalBottomRight from '@/components/base/mobile/BasePortalBottomRight'
import vuetify from '@/plugins/vuetify'
import store from '@/store'
import route from 'vue-router'
import axios from '@/plugins/axios'
import router from '@/router'

export default {
  mixins: [layersControls],
  methods: {
    _initControls () {
      this.initOverlayControls()
      this.initDrawControls()
    },
    scaleControl () {//масштаб
      const scaleLine = L.control.scale({
        metric: true,
        imperial: false,
        position: 'bottomleft'
      })
      this.controlLayers.scale = scaleLine
      scaleLine.addTo(this.map)
    },
    isDetailsFilled () {
      return this?.info?.detail && (this?.info?.type === 'passport' || this?.info?.type === 'object')
    },
    initDrawControls () {
      this.map.pm.setLang('ru')
      //   wrapperCoordinates.addEventListener('click', this.clickCoordinates)
    },
    bottomRightBtn () {
      const portal = L.Control.extend({
        options: {
          position: 'bottomright'
        },
        onAdd: function () {
          const ComponentPortal = Vue.extend(BasePortalBottomRight)
          const componentMount = new ComponentPortal({
            vuetify,
            store,
            route,
            axios,
            router
          }).$mount()
          L.DomEvent.disableClickPropagation(componentMount.$el)
          return componentMount.$el
        }
      })
      this.map.addControl(new portal())
    },
    //todo: один большой костыль!
    async _closeGeometry (data) {
      this.currentIdCreatingMapObject = null
      if (this.map.pm.globalDrawModeEnabled()) {
        this.map.pm.disableDraw()
      } else if (this.map.pm.globalDragModeEnabled()) {
        this.map.pm.disableGlobalDragMode()
      } else if (this.map.pm.globalEditModeEnabled()) {
        this.map.pm.disableGlobalEditMode()
        // генерится событие 'pm:disable', на котором срабатывает this.toggleGeometryAppBar
      } else {
        this.toggleGeometryAppBar(data)
        return setTimeout(() => {
          let self = this
          if (this.info.type === 'object') {
            if (!this.info.layer.isPopupOpen()) {
              self.info.layer.unbindPopup()
              const model = this.modelStatic.findModelByLayer(self.info.layer)
              model._popupPreview(self.info.layer, true)
            }
          }
          return true
        }, 0)
      }
      return true
    },
    async initOverlayControls (isNotOverlay) {
      if ((this.controlLayers.layers && !this.info.detail)) { //выход из паспорта на самый верхний уровень
        this.clearLayersControls()
        this.initTileLayers()
      } else if (this.info.detail && !this.controlLayers.layers) { //входим в паспорт
        try {
          this.controlLayers.overlay = this.getOverlayBySelectedType()
        } catch (e) {
          //console.log(e)
        }
        this.initTileLayers()
        await this.passportTileLayers()

        let baseLayers = this.controlLayers.tile.reduce((acc, cur) => {
          acc[cur.label] = cur.layer
          return acc
        }, {})

        this.controlLayers.layers = L.control.layers(baseLayers, null, {
          collapsed: true,
          position: 'topright'
        }).addTo(this.map)

        const closeButton = document.querySelector('.leaflet-control-layers-toggle')
        closeButton.classList.add('v-icon', 'mdi', 'mdi-layers-triple-outline')

        document.querySelector('.leaflet-control-layers-base').prepend('Картографическая основа')
        /*document.querySelector('.leaflet-control-layers-base').classList.add('white--text')*/
      } else if (!(this.info.detail || this.controlLayers.layers)) { //инициализация слоев
        this.initTileLayers()
      }
    },
    /*initSearchControls () {
      if (this.controlLayers.search) {
        this.map.removeControl(this.controlLayers.search)
      }
      if (this.info.type !== 'object' && this.info.type !== 'passport') {
        this.controlLayers.search = new SearchControls({ position: 'topright', info: this.info })
        this.map.addControl(this.controlLayers.search)
      }
    },*/
    /* initCustomTilesControls () {
       if (this.controlLayers.customTiles) {
         this.map.removeControl(this.controlLayers.customTiles)
       }
       if (this.info.type === 'passport') {
         this.controlLayers.customTiles = new CustomTiles({ position: 'topright', controlLayers: this.controlLayers })
         this.map.addControl(this.controlLayers.customTiles)
       }
     },*/
    initMeasureControls () {
      const measureControl = L.control.polylineMeasure(measureConfig)
      measureControl.addTo(this.map)
      this.controlLayers.measure = measureControl
      this.$refs.measureControl.appendChild(measureControl.getContainer())
    }
  }
}
