export default {
  data () {
    return {
      recomputeModel: 0, // атрибут для отмены кеширования models в modelStatic
    }
  },
  models: null,
  computed: {
    modelStatic: {
      get () {
        this.recomputeModel
        if (!this.$options.models) {
          this.$options.models = this.$store.getters['map/modelStatic']
        }
        return this.$options.models
      },
      set (val) {
        this.$options.models = val
        this.recomputeModel ++
      }
    },
    info: {
      get () {
        return this.$store.getters['map/info']
      },
      set (val) {
        this.$store.commit('map/info', val)
      }
    },
    center: {
      get () {
        return this.$store.getters['map/center']
      },
      set (val) {
        this.$store.commit('map/center', val)
      }
    },
    zoom: {
      get () {
        return this.$store.getters['map/zoom']
      },
      set (val) {
        this.$store.commit('map/zoom', val)
      }
    }
  }
}
